
import { computed, defineComponent, PropType, ref } from 'vue'
import { CourseDetail } from 'momai'
import { showtip } from '@/views/sales/product/useShowTip'
import Pay from '../Pay.vue'
import { courseSeriesPrice, detailCourseSeries } from '../course-list/state'

export default defineComponent({
  components: { Pay },
  props: {
    showPay: {
      type: Boolean,
      default: false
    },
    courseDetail: {
      type: Object as PropType<CourseDetail>,
      required: true
    },
    canWatchCourse: {
      type: Boolean,
      default: false
    },
    resetFn: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup: (props) => {
    const pay = ref<typeof Pay | null>(null)
    const showQrcode = computed(() => detailCourseSeries.value.type === 1 && !props.canWatchCourse)
    const recommendReward = detailCourseSeries.value.recommendReward
    const routerLeaving = ref(false)
    return {
      routerLeaving,
      showQrcode,
      courseSeriesPrice,
      showtip,
      pay,
      recommendReward,
      openPay: () => {
        const payInstance = pay.value
        if (payInstance) {
          payInstance.openPay()
        }
      }
    }
  }
})
