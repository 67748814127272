import wx from 'weixin-js-sdk'
import { ref } from 'vue'
import { Product, PayData } from 'momai'
import {queryProduct, jsApiConfig, queryActivityByType} from '@/api/sales'
import { getUserId } from '@/utils/auth'
import { changeShareInfo } from '@/utils/wechat'

const productList = ref<Product[]>([])

const fetchProductList = (size: number) => {
  queryProduct(size).then(r => {
    productList.value = r.records
  })
}

const {
  VUE_APP_REDIRECT_URI
} = process.env

const wxPay = (payData: PayData, payResCallBack = () => {}) => {
  jsApiConfig().then(configData => {
    wx.config({
      debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
      appId: configData.appId,
      timestamp: configData.timestamp,
      nonceStr: configData.nonceStr,
      signature: configData.signature,
      jsApiList: ['chooseWXPay']
    })
    wx.ready(function () {
      wx.chooseWXPay({
        appId: payData.appId,
        timestamp: payData.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: payData.nonceStr, // 支付签名随机串，不长于 32 位
        package: payData.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: payData.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: payData.paySign, // 支付签名
        success: function () {
          payData.flag = 0
          payResCallBack()
          // 支付成功后的回调函数
        },
        cancel: function () {
          payData.flag++
          payResCallBack()
        },
        fail: function () {
          payResCallBack()
          payData.flag++
        }
      })
    })
  })
}
const share = (product: Product) => {
  changeShareInfo({
    title: product.title,
    desc: product.description,
    imgUrl: product.img,
    link: VUE_APP_REDIRECT_URI + '/sales/product/' + product.id + '?referrer=' + getUserId() + '&'
  })
}
export {
  fetchProductList,
  productList,
  wxPay,
  share
}
