
import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import { wxPay } from '@/states/sales'
import { saveOrder, payOrder, checkOrder } from '@/api/sales'
import { getOpenid } from '@/utils/auth'
import { Notify } from 'vant'
import { buildOrderInfo } from 'momai'
import { detailCourseSeries } from './course-list/state'
import qs from 'qs'

export default defineComponent({
  props: {
    showEmpty: {
      type: Boolean,
      default: true
    },
    paySuccess: {
      type: Function as PropType<() => void>,
      default: () => {}
    },
    payBtnDisabled: {
      type: Boolean,
      default: false
    },
    showPayBtn: {
      type: Boolean,
      default: true
    }
  },

  setup: (props) => {
    const { referrer } = qs.parse(window.location.search.replace(/\?/, ''))

    const showBuyDialog = ref(false)
    const showPayingDialog = ref(false)
    const orderInfo = reactive(buildOrderInfo())
    const payMethod = ref(0)

    const resetPayMethod = () => {
      const cs = detailCourseSeries.value
      if(Number(cs.salePrice) > 0 || Number(cs.cashPrice) > 0){
        payMethod.value = 0
      }else{
        payMethod.value = 1
      }
    }
    resetPayMethod()

    const isCashPay = computed(() => payMethod.value === 0)
    const price = computed(() => {
      const cs = detailCourseSeries.value
      return isCashPay.value ? Number(cs.salePrice) > 0 ? cs.salePrice : cs.cashPrice : cs.magicBeanPrice
    })

    const dispalyPrice = computed(() => isCashPay.value ? (Number(price.value) / 100).toFixed(2) : price.value)
    return {
      detailCourseSeries,
      dispalyPrice,
      showBuyDialog,
      showPayingDialog,
      payMethod,
      isCashPay,
      openPay: () => {
        showBuyDialog.value = true
      },
      saveAndPayOrder: () => {
        orderInfo.fee = orderInfo.payFee = Number(price.value)
        orderInfo.feeType = payMethod.value
        orderInfo.type = 1
        orderInfo.productId = detailCourseSeries.value.id
        orderInfo.referrer = referrer
        saveOrder(orderInfo).then(orderId => {
          orderInfo.id = orderId
          orderInfo.openid = getOpenid()
          payOrder(orderInfo).then(r => {
            if (payMethod.value === 1) {
              Notify({
                type: 'success',
                message: '支付成功！'
              })
              props.paySuccess()
              return
            }
            wxPay(JSON.parse(r), () => {
              showPayingDialog.value = true
            })
          })
        })
      },
      finishPay: () => {
        checkOrder(orderInfo.id).then(r => {
          Notify({ type: r ? 'success' : 'danger', message: `付款${r ? '成功' : '失败'}` })
          showPayingDialog.value = false
          orderInfo.id = ''
          props.paySuccess()
        })
      }
    }
  }
})
