import { Dialog } from 'vant'

const showtip = (i: string) => {
  Dialog.alert({
    message: '点击右上角“···”，发给好友或者分享朋友圈，好友购买，您将会获得“' + i + '”魔豆奖励哦！'
  })
}

export {
  showtip
}
